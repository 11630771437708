/**
 * コンポーネント共通の購読リクエスト
 */
export class SubscribeRequest {
  private appno: string;
  private options: SubscribeOptions;
  constructor(appno: string, search: URLSearchParams | string) {
    this.appno = appno;
    this.options = new SubscribeOptions(search);
  }

  /**
   * App Storeへの遷移を越えて初回起動時のアプリに伝えたい情報を作成します
   */
  public toClipboardJSON(): string {
    return JSON.stringify({
      appno: this.appno,
      params: this.options.paramsToSubscribe.join(','),
      sync: this.options.getSync(),
    });
  }

  /**
   * URL Schemeを使って開くためのURLを返します
   */
  public toURLScheme(): string {
    const url = new URL(`${process.env.URL_SCHEME}://register/${this.appno}`);
    url.search = this.options.toURLSearchParams().toString();
    return url.toString();
  }
}

/**
 * 購読の際に渡すことができるオプション
 */
export class SubscribeOptions {
  // 既に購読している場合, ロード時の処理で使うもの
  private updateParams?: string[];
  private addParams?: string[];
  private removeParams?: string[];
  private sync?: boolean;

  constructor(search: URLSearchParams | string) {
    const searchParams =
      typeof search === 'string' ? new URLSearchParams(search) : search;

    this.sync = searchParams.get('sync') === 'true';

    const versionStr = searchParams.get('v');
    const version = versionStr === '2' ? 2 : 1;

    if (version !== 2) {
      // 古い指定の場合, 新しい指定に変換する
      const parameters = searchParams.get('p7')?.split(',');
      if (searchParams.get('p7refresh') !== null) {
        this.updateParams = parameters ?? [];
        return;
      }
      this.addParams = parameters;
      return;
    }

    const addParamsStr = searchParams.get('add_params');
    this.addParams = addParamsStr?.split(',');

    // removeParams
    const removeParamsStr = searchParams.get('remove_params');
    this.removeParams = removeParamsStr?.split(',');

    // updateParams
    const updateParamsStr = searchParams.get('update_params');
    this.updateParams = updateParamsStr?.split(',');
  }

  public toURLSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams({ v: '2' });
    if (this.sync) {
      searchParams.append('sync', 'true');
    }
    if (this.addParams) {
      searchParams.append('add_params', this.addParams.join(','));
    }
    if (this.updateParams) {
      searchParams.append('update_params', this.updateParams.join(','));
    }
    if (this.removeParams) {
      searchParams.append('remove_params', this.removeParams.join(','));
    }
    return searchParams;
  }

  /**
   * 初回起動時に利用する、購読したいパラメータ
   * updateParams + addParams - removeParams
   */
  public get paramsToSubscribe(): string[] {
    const s = new Set([
      ...(this.updateParams ?? []),
      ...(this.addParams ?? []),
    ]);
    (this.removeParams ?? []).forEach((paramToRemove) =>
      s.delete(paramToRemove)
    );
    return Array.from(s);
  }

  /**
   * 購読後に状態を同期するか返却する
   */
  public getSync(): boolean {
    return !!this.sync;
  }
}
