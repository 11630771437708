import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { AppSubscribeComponent } from '../components/AppSubscribeComponent';
import { AppData } from '../models/AppModel';
import { SubscribeRequest } from '../utils/SubscribeRequest';
import { TextOptionParser } from '../utils/TextOptionParser';

interface Props {
  url: URL;
  appno: string;
}

export const AppSubscribePage: React.FC<Props> = ({ appno, url }) => {
  const subscribeRequest = new SubscribeRequest(appno, url.searchParams);
  const textOptions = TextOptionParser(url.search);
  const [appData, setAppData] = React.useState<AppData | undefined>();
  React.useEffect(() => {
    let isSubscribed = true;
    (async () => {
      const result = await fetch(
        `${process.env.API}/${appno}/head`
      ).then((res) => res.json());
      if (isSubscribed) {
        setAppData(result);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [appno]);

  return appData ? (
    <AppSubscribeComponent
      app={appData}
      textOptions={textOptions}
      subscribeRequest={subscribeRequest}
    />
  ) : (
    <Grid container direction="column" justify="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );
};
