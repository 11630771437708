/**
 * iOS購読ページに表示するメッセージ群です
 */
export type TextOptions = {
  // 画面上部に表示されるメッセージ
  msg: string | null;
  // アプリを開くボタンのメッセージ
  openApp: string | null;
  // App Storeへのリンクのメッセージ
  downloadApp: string | null;
};

/**
 * iOS購読ページに表示するメッセージをURLから取得します
 * @param search ページのlocation.search
 */
export const TextOptionParser = (search: string): TextOptions => {
  const searchParams = new URLSearchParams(search);
  return {
    msg: searchParams.get('text-msg'),
    openApp: searchParams.get('text-open-app'),
    downloadApp: searchParams.get('text-dl-app'),
  };
};
