import * as React from 'react';
import { AppSubscribePage } from './pages/AppSubscribePage';
import { AppSyncPage } from './pages/AppSyncPage';

const appSubscribeRoute = /^\/apps\/(.*)/;
const appSyncRoute = /^\/sync\/(.*)/;

const App: React.FC = () => {
  const url = new URL(location.href);
  const path = url.pathname;

  const appSubscribeRouteMatch = path.match(appSubscribeRoute);
  if (appSubscribeRouteMatch) {
    const appno = appSubscribeRouteMatch[1];
    return <AppSubscribePage appno={appno} url={url} />;
  }

  const appSyncRouteMatch = path.match(appSyncRoute);
  if (appSyncRouteMatch) {
    const appno = appSyncRouteMatch[1];
    return <AppSyncPage appno={appno} />;
  }

  return null;
};

export default App;
