import * as React from 'react';
import { API } from '../utils/API';

interface Props {
  appno: string;
}

export const AppSyncPage: React.FC<Props> = ({ appno }) => {
  React.useEffect(() => {
    (async () => {
      const app = await API.head(appno);
      if (app.ios_sync_callback_url) {
        const redirectUrl = new URL(app.ios_sync_callback_url);
        redirectUrl.search = new URLSearchParams({
          subscribed: 'NO',
        }).toString();
        location.href = redirectUrl.toString();
      }
    })();
  }, []);
  return null;
};
