import * as React from 'react';
import Clipboard from 'react-clipboard.js';
import { AppData } from '../models/AppModel';
import { SubscribeRequest } from '../utils/SubscribeRequest';
import { TextOptions } from '../utils/TextOptionParser';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface Props {
  app: AppData;
  textOptions: TextOptions;
  subscribeRequest: SubscribeRequest;
}

export const AppSubscribeComponent: React.FC<Props> = ({
  subscribeRequest,
  textOptions,
  app,
}) => (
  <Container maxWidth="sm" className="topPadding">
    <Grid container direction="column" justify="center" alignItems="center">
      {textOptions.msg ? (
        <Typography paragraph variant="body1" align="center">
          {textOptions.msg}
        </Typography>
      ) : (
        <>
          <Typography paragraph variant="body1" align="center">
            「{app.name}」からの通知をPush7で受け取ります
          </Typography>
          <Typography paragraph variant="body1" align="center">
            「Push7を開く」か「購読用アプリをダウンロード」を選択して下さい。
          </Typography>
        </>
      )}
      <Box m={1}>
        <img width="200px" src={app.icon} />
      </Box>
      <Box m={1}>
        <Button
          size="large"
          color="primary"
          variant="contained"
          href={subscribeRequest.toURLScheme()}
        >
          {textOptions.openApp ?? 'Push7を開く'}
        </Button>
      </Box>
      <Box m={1}>
        <Clipboard
          data-clipboard-text={subscribeRequest.toClipboardJSON()}
          component="div"
        >
          <Button
            size="large"
            color="primary"
            variant="outlined"
            href={process.env.APPSTORE_LINK}
          >
            {textOptions.downloadApp ?? '購読用アプリをダウンロード'}
          </Button>
        </Clipboard>
      </Box>
    </Grid>
  </Container>
);
